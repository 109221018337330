@import '../components/colors.scss';

$light-border: 1px solid $light-border-color;

$dark-grey-border: 1px solid $dark-grey;

$active-border: 1px solid $active-border-color;

$divider-border: 1px solid $dark-divider-color;

$dark-border: 1px solid $default-color;

$alert-border: 1px solid $alert;
