.filterContainer {
  padding: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
}

.onlyButton {
  visibility: hidden;
  margin-left: 0.5rem;
}

.checkboxButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    .onlyButton {
      visibility: visible;
    }
  }
}

.checkboxIndicatorContainer {
  display: flex;
}

.typeIndicator {
  width: 5px;
  border-radius: 5px;
  height: 24px;
  margin-right: 6px;
}
