@import './ToastSizes.scss';
@import '../Motion.scss';

.toasterWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: hidden;

  padding: 0 $toast-margin $toast-margin;
  pointer-events: none;

  right: 0;
  z-index: 20;
  height: 100%;

  &.inPortal {
    position: fixed;
  }

  &.inline {
    position: absolute;
  }

  &.top {
    top: 0;
  }

  &.bottom {
    bottom: 0;
    flex-direction: column-reverse;
    top: auto;
  }

  &.left {
    align-items: flex-start;
  }

  &.right {
    align-items: flex-end;
  }
}

.toaster {
  transition: height 300ms $easing-movement;
}
