@import '../../components/colors.scss';
@import './ToastSizes.scss';
@import '../Motion.scss';
@import '../Font.scss';

.toastWrapper {
  min-width: 30px;
  color: $white;
  display: flex;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: fit-content;
  padding: $toast-padding;
  pointer-events: all;
  transition: transform 300ms $easing-movement, opacity 300ms $easing-movement,
    filter 300ms $easing-movement;
  opacity: 1;
  filter: blur(0);
  margin: $toast-margin;
}

.opening {
  transform: translateY(calc(-1 * (#{$toast-height} + #{$toast-padding})));
}

.opened {
  transform: translateY(0px);

  &.youngerSiblingDismiss {
    transition: transform 150ms $easing-movement;
    transform: translateY(calc(-1 * (#{$toast-height} + #{$toast-padding})));
  }
}

.bottom {
  &.opening {
    transform: translateY(calc(#{$toast-height} + #{$toast-padding}));
  }

  &.youngerSiblingDismiss {
    transition: transform 200ms $easing-movement;
    transform: translateY(calc((#{$toast-height} + #{$toast-padding})));
  }
}

.closing {
  opacity: 0;
  filter: blur(10px);
}

.title {
  font-weight: $semi-bold;
}

.default {
  background-color: $passively-active-indicator-color;
}

.error {
  background-color: $alert;
}

.warning {
  background-color: $secondary-color;
}

.success {
  background-color: $good-green;
}

.closeIcon {
  margin-left: 45px;
  cursor: pointer;
}

.icon {
  margin-right: 12px;
  div {
    cursor: unset;
  }
}
