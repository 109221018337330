/* 
AvailabilitiesDrawerButton is shown in a BlueprintJS Drawer,
and the way Blueprint does drawers blocks pointer events on
the rest of the screen, even if you do <Drawer hasBackdrop={false}>,
so this code overrides that. If the drawer is open, you can still
click and navigate the rest of the screen.
*/

.portal :global .bp4-overlay {
  pointer-events: none;
}

.portal :global .bp4-overlay-content {
  pointer-events: all;
}
