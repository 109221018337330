@import '../../components/colors.scss';

.checkboxWrapper {
  display: flex;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  align-items: center;
  width: fit-content;
  outline: none;

  &.isDisabled {
    cursor: not-allowed;
    opacity: $disabled-opacity;
  }
}

.checkbox {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background: $light-grey;
  border: 1px solid $dark-grey;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkboxLabel {
  margin-left: 5px;
  margin-bottom: 0px;
  cursor: pointer;

  &.isDisabled {
    cursor: pointer;
    color: $disabled-text-color;
    cursor: not-allowed;
  }
}
