@import '../colors.scss';

.tabIndicatorWrapper {
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateX(0), translateY(0);
  transition: height, transform, width, color;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);

  .tabIndicator {
    background: #202020;
    border-radius: 8px 0px 0px 8px;
    transform: matrix(0, 1, 1, 0, 0, 0);
    width: 4px;
    height: 53px;
    position: absolute;
    left: 47%;
  }
}

.tabList {
  align-items: flex-end;
  border: none;
  display: flex;
  flex: 0 0 auto;
  list-style: none;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;

  > *:not(:last-child) {
    margin-right: 40px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $passively-active-indicator-color;
  margin-top: 24px;
}

.tabPanel {
  margin-top: 20px;

  &[aria-hidden='true'] {
    display: none;
  }
}

.stickyHeader {
  position: sticky;
  top: 0;
  background: $white;
}
