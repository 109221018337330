// Pure color values
// Main colors
$primary-color-dulled: #4a5eb5;
$primary-color: #24358d;
$active-primary-color: #0e1c68;
$secondary-color: #ff8b00;
$secondary-color-light: #fbf2d5;
$primary-color-light: #efedfa;
$primary-color-light-active: #e1ddfa;
$light-purple: #eee8ff;
$light-green: #cdfae6;
$default-color: #202020;
$alert: #ce0000;
$blue-fog: #eaf5ff;
$purple-indicator: #6e3de6;
$purple: #6345bb;
$green: #258c5f;
$good-green: #00ac11;
$warning-orangeish-yellow: #ac6700;
$navy-blue: #142251;
$gentle-lavender: #f8f7ff;
$night-blue: #1a3f86;
$midnight-blue: #062560;
$magenta-ish: #754f8d;
$lavender: #edd7fb;
$uh-oh-orange: rgb(172, 103, 0);
$tangerine-orange: #e66933;

$grassy-green: #009257;
$pale-blue: #4a5eb5;
$golden-orange: #e9bb01;
$lighter-golden-orange: #fce6bb;
$sky-blue: #65eaf6;
$lighter-sky-blue: #cafaff;

// Calendar colors
// NOTE JMB: These colors should be reconciled
// with the colors in CalendarV2/Common.scss.
// these are meant to be common colors across
// the MEHome platform, but the designs have diverged a bit.
$in-person-tour-color: $purple-indicator;
$virtual-tour-color: $pale-blue;
$self-guided-tour-color: $grassy-green;

// Grays/Whites
$white: #ffffff;
$greyForWhiteContrast: #f5f7f9;
$granite-gray: #f4f6f9;
$dash-color: #e8edf3;
$field-color: #f5f7f9;
$disabled-opacity: 0.5;
$dark-grey: #83818e;
$light-grey: #e3e3e3;
$another-light-gray: #f8f9fd;

$graphite-grey: #8e8e8e;
$dark-light-grey: #e8e8e8;

$herbal-green: #037d00;

$teal-ish: #00b7ac;

// Applications of colors

$background-color: $white;

// Metric types
$responses-metric-color: $secondary-color-light;
$leads-metric-color: $light-purple;
$efficiency-metric-color: $light-green;

// Text
$text-color: $default-color;
$disabled-text-color: rgba($text-color, $disabled-opacity);
$inactive-text-color: $dark-grey;
$default-link-text-color: $primary-color;
$dark-link-text-color: $navy-blue;

// Borders
$light-border-color: $light-grey;
$active-border-color: $default-color;
$dark-divider-color: $default-color;
$light-divider-color: $light-grey;

$responses-metric-border-color: $secondary-color;
$leads-metric-border-color: $purple;
$efficiency-metric-border-color: $green;

// Backgrounds
$input-field-color: $field-color;
$passively-active-indicator-color: $default-color;
$dashed-background: repeating-linear-gradient(
  -45deg,
  $white,
  $white 10px,
  $field-color 10px,
  $field-color 20px
);
$nav-bar-background: $another-light-gray;
$nav-bar-item-background-active: linear-gradient(
  102.61deg,
  #00ac11 -150.11%,
  #097568 93.57%
);

// States
$disabled-color: $field-color;
$hover-background-color: rgb($dark-grey, 0.1);
$active-background-color: rgba($dark-grey, 0.2);
$success-color: $good-green;
$warning-color: $uh-oh-orange;
$failure-color: $alert;
