@import './Font.scss';
@import '../components/colors.scss';

.semi-bold-text {
  font-weight: $semi-bold;
}

.link-text {
  color: $default-link-text-color;
  text-decoration: none;

  &:active,
  &:hover {
    color: $default-link-text-color;
    text-decoration: none;
  }
}

.informative-text {
  font-size: 12px;
  line-height: 18px;
}
