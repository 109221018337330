.container {
  overflow-y: scroll;
}

.horizontalWrapped {
  display: flex;
  flex-wrap: wrap;
}

.buttons {
  background: white;
  z-index: 10;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
}

.disabledContainer {
  opacity: 0.5;
}
