@import '../colors.scss';
@import '../../vanillaelise/Font.scss';
@import '../../vanillaelise/Borders.scss';

$active-tertiary-button-color: $field-color;

.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.primaryButton {
  background: $primary-color;
  border: 1px solid $primary-color;
  box-sizing: border-box;
  color: $white;
  box-shadow: 0px 2px 0px rgba(36, 53, 141, 0.25);

  :not(.noTransitions) {
    &:hover {
      border: 1px solid $secondary-color;
    }
    &:active {
      background-color: $active-primary-color;
      border: 1px solid $primary-color;
      transform: translateY(2px);
      box-shadow: 0px 0px 0px rgba(36, 53, 141, 0.25);
    }
  }

  &.minimal {
    color: $primary-color;
    &:not(.noTransitions) {
      &:hover {
        background-color: lighten($color: $primary-color, $amount: 60%);
      }
      &:active {
        background-color: lighten($color: $primary-color, $amount: 50%);
      }
    }
  }
}

.secondaryButton {
  background: $primary-color-light;
  color: $primary-color;
  border: 2px solid $primary-color-light;
  box-shadow: 0px 2px 0px rgba(36, 53, 141, 0.25);

  &:not(.noTransitions) {
    &:hover {
      border: 1px solid $secondary-color;
      padding: 13px 21px;
    }
    &:active {
      background-color: $primary-color-light-active;
      border: 2px solid $primary-color-light-active;
      transform: translateY(2px);
      box-shadow: 0px 0px 0px rgba(36, 53, 141, 0.25);
      padding: 12px 20px;
    }
  }
  &.minimal {
    padding: 13px 21px;
    &:not(.noTransitions) {
      &:hover {
        background-color: $primary-color-light;
      }
      &:active {
        background-color: $primary-color-light-active;
      }
    }
  }
}

.tertiaryButton {
  background: $white;
  color: $primary-color;
  border: $light-border;
  box-shadow: 0px 2px 0px rgba(36, 53, 141, 0.25);
  padding: 13px 21px;

  &:not(.noTransitions) {
    &:hover {
      border: 1px solid $secondary-color;
      padding: 12px 20px;
    }
    &:active {
      background-color: $active-tertiary-button-color;
      border: $light-border;
      transform: translateY(2px);
      box-shadow: 0px 0px 0px rgba(36, 53, 141, 0.25);
      padding: 12px 20px;
    }
  }
}

.disabled {
  background: #f5f7f9;
  border: 1px solid #c0c6de;
  box-shadow: 0px 2px 0px rgba(36, 53, 141, 0.25);

  .disabledText {
    color: $primary-color;
    opacity: 0.5;
  }
}

.hasIconNeighbor {
  margin-left: 8px;
}

.button {
  border-radius: 10px;
  padding: 12px 20px;
  font-weight: $semi-bold;

  &.disabled {
    cursor: not-allowed;
  }
}

.minimal {
  background: transparent;
  border: 0;
  box-shadow: none;
  padding: 4px 12px;
  &:not(.noTransitions) {
    &:hover,
    &:active {
      border: 0;
      transform: none;
      box-shadow: none;
    }
  }
}

.rightElement {
  margin-left: 5px;
}

.loading {
  height: 26px;
  width: 26px;
  pointer-events: none;
}
