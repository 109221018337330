@import '../../components/colors.scss';
@import url('https://fonts.googleapis.com/css?family=Poppins');

.page {
  display: flex;
  font-family: Poppins, proxima-nova, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $text-color;

  height: 100%;
  min-height: 864px;
  overflow: auto;
}

.pageContent {
  height: 100%;
  width: 100%;
}

.children {
  height: 100%;
  position: relative;
  background-color: $background-color;
  padding: 20px;
}

.scrollableContent {
  overflow: scroll;
}

.legacyContent {
  padding: 30px;
}
