.card {
  position: relative;
}

.tagTreeButton {
  position: absolute;
  top: 0;
  right: 0;
}

:global(.bp4-card) .tag {
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.predictedTag {
  position: absolute;
  top: 0;
  right: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: forestgreen;
  font-weight: 400;
}

.eventTypeTag {
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.displayLinebreak {
  white-space: pre-wrap;
  padding-left: 16px;
  border-left: 2px lightgray solid;
  margin-top: 16px;
  margin-bottom: 16px;
  overflow: auto;
  max-height: 400px;
}

.aitTag {
  position: absolute;
  top: 0;
  right: 85px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.eventId {
  cursor: pointer;
}
