.pageContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 51px;
}

.sideBar {
  display: flex;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 97px;
  align-self: flex-start;
  flex-basis: 13rem;
  flex-grow: 1;
  padding: 0 3rem;
}

.mainPage {
  flex-basis: 0;
  flex-grow: 999;
}

.stickyHeader {
  position: sticky;
  /* 50px is defined by blueprint Navbar and we are just mirroring it here */
  top: 51px;
  background-color: #f5f8fa;
  z-index: 10;
  padding: 4px 1px;
}
