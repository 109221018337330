@import '../../components/colors.scss';
@import '../../vanillaelise/Font.scss';

.tabTitle {
  color: $primary-color;
  font-weight: $semi-bold;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  position: relative;
  flex: 0 0 auto;
  vertical-align: top;
  cursor: pointer;
  outline: none;

  &.isSelected {
    color: #202020;
  }

  &[aria-disabled='true'] {
    color: #e8e8e8;
    cursor: not-allowed;
  }
}
