@import '../../components/colors.scss';

.nonIdealState {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;

  > * {
    max-width: 400px;
    margin-bottom: 20px;
  }
}

.header {
  font-size: 18px;
  line-height: 21px;
}

.attentionGrabber {
  color: $disabled-text-color;
  font-size: 60px;
}
