.chartContainer {
  max-height: 80vh;
  overflow-y: auto;
  margin: 1rem;
}

.timexChart {
  color: black;
  table,
  th,
  td {
    border: 1px solid black;
  }
}
