@import '../../components/colors.scss';
@import '../../vanillaelise/Borders.scss';
@import '../../vanillaelise/Font.scss';

$left-padding: 22px;

.navBarWrapper {
  height: 100%;
  display: flex;
  background-color: $nav-bar-background;
  width: fit-content;
  font-family: Poppins;
  color: $default-color;
  font-weight: $semi-bold;
  font-size: 13px;
  letter-spacing: 0.03em;
}

.meetEliseTextWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7rem;
  width: 100%;
  cursor: pointer;
}

.navBar {
  width: 12.5rem;
  height: 100%;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.navItem {
  height: 2.5rem;
  width: 91%;
  padding: 9px 0 10px $left-padding;
  color: $text-color;
  text-decoration: none;
  display: flex;
  align-items: center;
  line-height: 18px;
  transition: background-color 200ms cubic-bezier(0.2, 0.19, 0.27, 0.98);
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 2px;
  }

  &:hover {
    background-color: $primary-color-light;
    color: $text-color;
    text-decoration: none;
  }

  &.selected {
    background: $nav-bar-item-background-active;
    color: $white;
  }

  .navItemContent {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;

    .iconContainer {
      width: 1.625rem;
    }
  }
}

.hidden {
  // opting to remove opacity to keep spacing consistent
  opacity: 0;
}

.internalTools {
  margin-top: 2.375rem;

  .navSectionHeader {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-transform: uppercase;
    letter-spacing: 0;
  }
}

.hr,
.navSectionHeader {
  margin-left: $left-padding;
  margin-bottom: 11px;
}

.hr {
  margin-right: 19px;
  width: 159px;
  height: 0px;
  border-bottom: 0.5px solid $light-grey;
  margin-bottom: 1rem;
}

.miscellaneousItems {
  margin-top: 20px;
  margin-bottom: 20px;
}
