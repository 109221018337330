@import '../../components/colors.scss';
@import '../../vanillaelise/Borders.scss';

.flexContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.headerWrapper {
  height: 100px;
  width: 100%;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userActionsWrapper {
  position: relative;
}

.userWrapper {
  width: fit-content;
  margin-right: 45px;
  flex: 0 0 90px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.user {
  display: flex;
  align-items: center;
  margin-right: 14px;
  color: $dark-link-text-color;
}

.userName {
  font-weight: 400;
  line-height: 28px;
  margin-left: 10px;
}

.headerContentWrapper {
  flex-grow: 1;
}

.userActionsWrapper {
  display: flex;
  align-items: center;
  font-weight: normal;
  line-height: 40px;
}

.popover {
  margin: 8px 0;
  background-color: $white;
  z-index: 20;
  border: $light-border;
  box-sizing: border-box;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  position: absolute;
}

.popoverContent {
  padding: 12px 14px 16px 19px;
  width: 100%;
  height: 100%;
}

.disabledEmail {
  color: $disabled-text-color;
}

.link {
  transition: background-color 200ms cubic-bezier(0.2, 0.19, 0.27, 0.98);
  cursor: pointer;

  &:hover {
    background-color: $hover-background-color;
  }

  &:active {
    background-color: $active-background-color;
  }
}

.signOut {
  cursor: pointer;
  margin-top: 10px;
  border-top: $light-border;
  transition: background-color 200ms cubic-bezier(0.2, 0.19, 0.27, 0.98);
  cursor: pointer;

  &:hover {
    background-color: $hover-background-color;
  }

  &:active {
    background-color: $active-background-color;
  }
}
