.selectButton {
  visibility: hidden;
}
.menuItem {
  display: flex;
  justify-content: space-between;
  &:hover {
    .selectButton {
      visibility: visible;
    }
  }
}
