table.expandLast {
  width: auto;
}

.expandLast td {
  white-space: nowrap;
}
.expandLast td:last-child {
  width: 100%;
}
.scrollWrapper {
  overflow-x: scroll;
}

.titleAndActions {
  display: flex;
  align-items: baseline;
}

.clearFitlersButton {
  margin-left: 1rem;
}

.copieableTableCell {
  cursor: pointer;
}

.copieableTableCell:hover {
  color: blue;
}

/* Need in order to allow the columns to not render over eachother (z-index issues) */
th {
  background: white;
}
td {
  background: white;
}
